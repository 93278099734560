import React from "react";
import RateFormat from "../rate-format";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const RateTableRow = ({ rate }) => {
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#004b8d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <StyledTableRow key={rate.Id}>
      <StyledTableCell>
        {rate.Name}{" "}
        {rate.SpecialRateInformation !== "" && (
          <span>({rate.SpecialRateInformation})</span>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <RateFormat value={rate.BuyRate} name={rate.Name} />
      </StyledTableCell>
      <StyledTableCell>
        <RateFormat value={rate.SellRate} name={rate.Name} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RateTableRow;
