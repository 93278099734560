import React from "react";
import RateTableRow from "../rate-table-row";
import RateHeader from "../rate-header";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const RateTable = function ({ rates }) {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const classes = useStyles();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#004b8d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <div
      style={{
        maxWidth: "1140px",
        margin: "auto",
        fontSize: "24px",
        fontFamily: "Roboto",
      }}
    >
      <div>
        {rates[0]?.DateUpdated != null && (
          <RateHeader dateUpdated={rates[0].DateUpdated} />
        )}
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Currency</StyledTableCell>
                <StyledTableCell>Buy rate</StyledTableCell>
                <StyledTableCell>Sell rate</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.map((rate) => {
                return <RateTableRow rate={rate} key={rate.Id}></RateTableRow>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default RateTable;
