import React from "react";
import { DateTime } from "luxon";

const RateHeader = ({ dateUpdated }) => {

  const dateTime = DateTime.fromISO(dateUpdated);
  return <h4>Rates as of {dateTime.toFormat("dd-MM-yyyy hh:mm a")}</h4>;
};

export default RateHeader;
