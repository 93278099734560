import "./App.css";
import RateTable from "./components/rate-table";
import React, { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const [rates, setRates] = useState([]);
  const getRates = async () => {
    const doGetRates = await axios.get(
      "https://unitedcurrencyexchange.com.au/api/Rates.php"
    );
    setRates(doGetRates.data.result);
  };

  useEffect(() => {
    getRates();
    const interval = setInterval(() => {
      getRates();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <RateTable rates={rates} />
    </div>
  );
}

export default App;
