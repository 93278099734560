import React from "react";
import NumberFormat from "react-number-format";

const RateFormat = ({ value, name }) => {
  const getDecimalPlace = (name) => {
    return 4;
  };
  const roundedValue = parseFloat(value).toFixed(4);
  return (
    <NumberFormat
      value={roundedValue}
      displayType={"text"}
      decimalScale={getDecimalPlace(name)}
      thousandSeparator=","
    />
  );
};

export default RateFormat;
